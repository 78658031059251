div.OwnerPageEditor {
    height: 100%;
    position: relative;
    div.loading--view {
        height: 100%;
        width: 100%;
        position: absolute;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 10;
        background-color: #fff;

        svg {
            font-size: 100px;
        }

        p {
            font-size: 16px;
        }
    }
    div.page--editor {
        display: flex;
        overflow: hidden;
        width: 100%;
        flex-wrap: nowrap;
        @media (max-width: 600px) {
            flex-wrap: wrap;
        }
        
        label[for=carousel--image--picker] {
            display: flex;
            align-items: center;
            input[type=file] {
                visibility: hidden;
                position: absolute;
            }
            div.select--files {
                background-color: var(--owner-primary-shade-one);
                width: 100%;
                display: flex;
                justify-content: space-between;
                color: #fff;
                font-weight: 300;
                border-radius: 3px;
                cursor: pointer;
                svg {
                    margin-left: 10px;
                }

                @media(max-width: 600px) {
                    width: 100%;
                }
            }
        }
    }
    div.editor--tree {
        flex: 0 1 300px;
        min-width: 300px;
        margin-right: 10px;
        margin-bottom: 10px;

        div.editing--notes {
            margin-top: 20px;
            background-color: #fff;
            border-radius: 4px;
            padding: 20px;
            p {
                color: var(--owner-complement-shade-two);
                font-weight: 300;
                strong {
                    font-weight: 300;
                    text-decoration: underline;
                }
            }
        }
    }

    div.page--preview {
        flex: 1 1 auto;
        overflow-y: auto;
    }


}