div.MemberNoteForm {
    textarea {
        outline: 1px solid rgba(0, 0, 0, 0.2);
        color: var(--medium-grey);
        font-size: 13px;
        
        border: none;
        width: 100%;
        resize: none;
    }
}