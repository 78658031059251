a.ClubData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    position: relative;
    outline: none;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background-color: var(--owner-complement-shade-two);
        transform: scaleX(0);
        transition: transform 180ms ease-in-out;
    }
    &:hover::after {
        transform: scaleX(1);
    }
    div.club--details {
        div.club--name {
            color: var(--medium-grey);
            font-size: 15px;
        }
        span.other--data {
            display: block;
            font-size: 14px;
            margin-top: 6px;
            color: var(--light-grey);
        }
    }
    svg {
        color: var(--light-grey);
    }
}