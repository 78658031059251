div.OwnerGeneral {
    @media (max-width: 768px) {
        padding-bottom: 80px;
    }
    div.data--card--container {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1200px) {
            flex-wrap: wrap;
            flex: 0 1 49%;
        }

        span.update {
            display: flex;
            align-self: center;
            svg.increase {
                color: var(--status--success--color);
                margin-right: 3px;
                font-size: 20px;
            }
            span.text {
                display: flex;
                align-self: center;
                font-size: 15px;
            }
        }
    }

    section.clubs {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        @media(max-width: 600px) {
            flex-wrap: wrap;
        }
        div.item {
            flex: 0 1 33%;
            min-height: 200px;
            background-color: #fff;
            padding: 20px;
            border-radius: 4px;
            @media(max-width: 600px) {
                flex: 0 1 100%;
                &:not(:first-of-type) {
                    margin-top: 20px;
                }
                &:last-of-type {
                    display: none;
                }
            }
            h2.item--header {
                font-size: 20px;
                font-weight: 600;
                color: var(--dark-text);
                margin-bottom: 20px;
            }

            div.club--data {
                display: flex;
                &:not(:first-of-type) {
                    margin-top: 25px;
                }
                div.icon--container {
                    display: flex;
                    align-items: center;
                    border-radius: 4px;
                    margin-right: 18px;
                    background-color: #BBDEFB;
                    padding: 8px;

                    svg {
                        color: #1976D2;
                    }
                }

                div.club--detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    span {
                        line-height: 18px;
                        font-size: 15px;
                    }
                    span:first-of-type {
                        color: var(--medium-grey);
                    }
                    span:nth-of-type(2) {
                        color: var(--light-grey);
                    }
                }

                &:nth-of-type(2) {
                    div.icon--container {
                        background-color: #FFF9C4;
    
                        svg {
                            color: #FBC02D;
                        }
                    }
                }

                &:nth-of-type(3) {
                    div.icon--container {
                        background-color: #B2EBF2;
    
                        svg {
                            color: #0097A7;
                        }
                    }
                }
            }
        }
    }
}