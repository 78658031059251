button.Button {
    padding: 10px;
    text-transform: uppercase;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    transition: background-color 230ms ease-in-out;

    div.MuiCircularProgress-root {
        svg {
            color: #757575;
        }
    }

    &.dark-red {
        background-color: var(--dark-red);
    }

    &.dark-red-color {
        color: var(--dark-red);
    }

    &.success {
        background-color: var(--success);
    }

    &.accent--two {
        background-color: var(--accent-one-shade-one);
    }

    &.accent-two-color {
        color: var(--accent-two-shade-two);
        &:hover {
            background-color: #FFF3E0;
        }
        &:active {
            background-color: var(--accent-two-shade-one);
        }
    }

    background-color: var(--accent-one-shade-two);

    &[data-button-type=text] {
        width: auto;
        background-color: transparent;
        box-shadow: none;
        transition: background-color 300ms ease-in-out;
    }

    &[data-button-type=block] {
        width: 100%;
    }
}