div.DataCard {
    border-radius: 4px;
    background-color: #fff;
    padding: 20px;
    flex: 0 1 24.5%;
    color: var(--medium-grey);
    margin-top: 10px;

    @media (max-width: 1200px) {
        flex: 0 1 49%;
    }

    @media (max-width: 500px) {
        flex: 0 1 100%;
    }

    div.count--row {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        div.icon--label {
            svg {
                margin-right: 5px;
            }
            display: flex;
            align-items: center;
        }
        span.count {
            font-size: 26px;
        }
    }
    div.update--row {
        margin: 6px 0 15px 0;
        font-size: 12px;
        font-weight: 700;
        color: var(--light-grey);
    }

    div.chart {
        position: relative;
    }

    canvas {
        width: 100% !important;
    }
}