div.InputGroup {
    label {
        border: 1px solid rgba(54, 36, 36, 0.15);
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: border-color 250ms ease-in-out;
        position: relative;
        background-color: #fff;

        &:focus-within {
            border-color: var(--accent-one-shade-one);
        }

        &:focus-within span.input--group__icon {
            color: var(--accent-one-shade-three);
        }

        span.input--group__error {
            position: absolute;
            left: 0;
            bottom: -16px;
            color: var(--dark-red);
            font-size: 12px;
            visibility: hidden;
            &.visible {
                visibility: visible;
            }
            opacity: 1;
        }
        
        span.input--group__icon {
            flex: 0 1 50px;
            padding: 10px 15px;
            color: #74788d;
            background-color: #f7f8fa;
            align-items: center;
            transition: color 250ms ease-out;    
            i {
                font-size: 14px;
            }
            svg {
                display: flex;
                align-items: center;
            }
        }

        &[data-read-only="true"] {
            background-color: #E0E0E0;
            border-color: transparent;
            input {
                background-color: #E0E0E0;
                color: #9E9E9E;
                user-select: none;
                -moz-user-select: none;
            }

            span.input--group__icon {
                background-color: #E0E0E0;
                border-right: 1px solid #BDBDBD;
            }
        }

        input {
            border: none;
            font-size: 16px;
            box-shadow: none;
            color: var(--medium-grey);

            margin-left: 10px;
            margin-right: 10px;
            flex: 0 1 100%;
            outline: none;
            min-width: 0;
            font-size: 14px;
        }

        span.visibility--toggle {
            border: none;
            outline: none;
            background-color: transparent;
            margin: 0 10px;
            display: flex;
            align-items: center;
            svg {
                color: rgba(0, 0, 0, 0.4);
                font-size: 20px;
            }
        }
    }

    div.input--group--error {
        text-align: left;
        color: var(--dark-red);
        font-size: 12px;
        min-height: 18px;
    }
}
div.InputGroup div.input--error {
    color: var(--dark-red);
    font-size: 12px;
    min-height: 18px;
}