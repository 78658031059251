div.TabElement {
    ul.tab--element__navigation {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        align-items: center;
        @media (max-width: 600px) {
            flex-wrap: wrap;
            height: auto;
        }
        li {
            @media (max-width: 600px) {
                flex: 0 1 100%;
            }   
            margin-right: 10px;
            height: inherit;
            
        }
        a.tab--element__navigator {
            outline: none;
            height: inherit;
            padding: 10px;
            background-color: transparent;
            border: none;
            font-size: 14px;
            color: var(--medium-grey);
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            @media (max-width: 600px) {
                width: 100%;
                text-align: left;
            }
            &.active {
                color: var(--accent-one-shade-three);
            }
            svg {
                font-size: 40px;
                margin-bottom: 10px;
            }
            span {
                display: inline-block;
                height: 21px;
                transition: color 250ms ease-in-out;
            }
        }
    }
}