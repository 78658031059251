div.header--space {
    height: 80px;
}

header.MemberHeader {
    height: 80px;
    padding: 0 40px;
    box-shadow: var(--shadow-type-three);
    background-color: #fff;
    width: 100%;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    @media (max-width: 600px) {
        padding: 0 20px;
    }

    figure.header--logo {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
            height: 70px;
            width: auto;
            margin-right: 8px;
        }
        figcaption {
            position: relative;
            top: -5px;
            font-size: 14px;
            color: var(--accent-one-shade-four);
            div:first-of-type {
                font-size: 20px;
                @media (max-width: 600px) {
                    font-size: 14px;
                }
            }
        }
    }

    div.header--menu {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        div.mobile--menu {
            display: none;
            @media (max-width: 900px) {
                display: block;
            }
        }

        ul.menu--standard {
            @media (max-width: 900px) {
                display: none;
            }
            display: flex;
            li {
                position: relative;
                span.border {
                    position: absolute;
                    background: #fff;
                    display: block;
                    width: 100%;
                    height: 1px;
                    left: 0;
                    bottom: -4px;
                    border-radius: 2px;
                    transition: transform 180ms ease-in-out;
                    transform: scaleX(0);
                }
                &:hover span.border {
                    transform: scaleX(1);
                }

                &:not(:last-of-type) {
                    margin-right: 20px;
                }
                a {
                    outline: none;
                    font-weight: 300;
                    color: inherit;
                    &.active ~ span.border {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}