div.MemberMenu {
    padding: 20px;
    @media (max-width: 768px) {
        padding: 10px;
    }
    div.member--menu--content {
        min-height: inherit;
        background-color: #fff;
        padding: inherit;
        div.header--container {
            text-align: center;
            p.header {
                color: var(--dark-text);
                font-size: 24px;
                margin-bottom: 10px;
            }
        }
    }
    div.menu--container {
        border-radius: 4px;
        display: grid;
        grid-template-columns: repeat(3, 300px);
        gap: 20px;

        align-content: center;
        justify-content: center;

        div.Card {
            margin-top: 10px;
        }

        @media (max-width: 1030px) {
            grid-template-columns: repeat(2, 300px);
        }

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            gap: 0px;
            align-items: center;

            div.Card {
                img {
                    width: 350px;
                }
            }
        }

        @media (max-width: 450px) {
            div.Card {
                img {
                    width: 100%;
                }
            }
        }
    }

    a.action--link {
        color: var(--link-color);
        padding: 10px 10px;
        font-size: 13px;
        transition: background-color 180ms ease-in-out;
        background-color: transparent;
        border-radius: 4px;
        &:hover {
            background-color: rgba(25, 118, 210, 0.04);
        }
    }
}