div.OwnerMembersViewMember {
    div.LoadingScreen {
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.MemberInformation {
        @media (max-width: 800px) {
            margin-bottom: 60px;
        }
        div.data--block {
            padding: 20px 35px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
            @media(max-width: 600px) {
                padding: 20px 25px;
            }
            div.full--name {
                display: flex;
                align-items: center;
                svg {
                    margin-left: 10px;
                    color: var(--owner-complement-shade-two);
                    font-size: 20px;
                }
            }

            div.contact--information {
                display: flex;
                align-items: center;
                color: var(--grey);
                font-weight: 300;
                font-size: 14px;
                @media (max-width: 600px) {
                    flex-wrap: wrap;
                }
                svg {
                    font-size: 16px;
                    color: var(--light-grey);
                    margin-right: 6px;
                }
                div.icon--data--pair {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    @media (max-width: 600px) {
                        flex: 0 1 100%;
                    }
                }
            }

            div.other--information {
                margin-top: 20px;
                font-size: 16px;
                color: var(--medium-grey);
                span.key {
                    margin-right: 20px;
                }
            }

            section.linked--accounts {
                margin-top: 28px;
                div.accounts--container {
                    div.account {
                        display: block;
                        margin-top: 10px;
                        div.key--value--pair {
                            display: inline-flex;
                            align-items: center;
                            margin-right: 10px;
                            div.key {
                                margin-right: 10px;
                                color: var(--medium-grey);
                                font-size: 14px;
                            }
                            div.value {
                                color: var(--light-grey);
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
        div.border {
            background-color: rgba(0, 0, 0, 0.1);
            height: 1px;
            margin: 20px 0px 0px 0px;
        }

        section.transactions {
            display: flex;
            align-items: center;
            @media (max-width: 1200px) {
                flex-wrap: wrap;
            }
            div.data--item {
                display: flex;
                color: var(--dark-text);
                margin-right: 25px;
                margin-top: 10px;
                @media (max-width: 1200px) {
                    flex: 0 1 49%;
                    margin-right: 0px;
                }
                @media (max-width: 600px) {
                    flex: 0 1 100%;
                }
                svg {
                    font-size: 40px;
                    color: #B5B5C3;
                    margin-right: 12px;
                }
                div.text {
                    div.label {
                        font-size: 14px;
                    }
                    div.description {
                        font-size: 16px;
                    }
                }
            }
        }

        section.referrer,
        section.referrals,
        section.linked--accounts {
            margin-top: 20px;
            p.title {
                font-size: 16px;
                color: var(--dark-text);
                display: flex;
                align-items: center;
                img {
                    margin-left: 8px;
                    width: 16px;
                    height: 16px;
                }

                svg {
                    width: 20px;
                    height: 20px;
                    color: var(--owner-complement-shade-two);
                }
            }
            div.details {
                margin-top: 20px;
                display: flex;
                div.data {
                    margin: 12px 25px 0 0;
                    div.label {
                        color: var(--medium-grey);
                        font-size: 14px;
                        user-select: none;
                    }
                    div.information {
                        color: var(--light-grey);
                        font-size: 12px;
                    }
                }
            }
        }

        section.referrer {
            @media(max-width: 600px) {                
                div.details  {
                    flex-wrap: wrap;
                    display: flex;
                    div.data {
                        flex: 0 1 100%;
                    }
                }
            }
        }

        section.referrals {
            margin-top: 0px;
            display: flex;
            justify-content: space-between;
            td {
                height: 72px;
            }

            @media (max-width: 1200px) {
                flex-wrap: wrap;
            }
            
            p.table--name {
                min-height: 55px;
                padding: 20px 35px 0 35px;
                background-color: #fff;
                img {
                    margin-left: 8px;
                    width: 16px;
                    height: 16px;
                }
            }


            div.table--section:not(.full) {
                flex: 0 1 49.5%;
            }

            div.notes--section {
                flex: 0 1 49.5%;
                p.title {
                    line-height: 24px;
                    font-size: 14px;
                    color: var(--grey);
                    padding: 20px 35px 0 35px;
                    position: relative;
                    min-height: 55px;
                    button.add--note--button {
                        position: absolute;
                        right: 32px;
                    }
                }
                div.action--buttons {
                    display: flex;
                    align-items: center;
                    button {
                        svg {
                            width: 18px;
                            height: 18px;
                        }
                        padding: 7px;
                    }
                    button.edit--button {
                        color: var(--status--success--color);
                    }
                    button.delete--button {
                        position: relative;
                        left: -3px;
                        color: var(--dark-red);
                    }
                }
            }
            
            div.table--section, div.notes--section {
                margin-top: 10px;
                max-width: 100%;
                width: 100%;
                @media (max-width: 1200px) {
                    flex: 1 1 100%;
                }
            }
        }
    }

    div.MemberError {
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        p.main--header {
            font-size: 40px;
        }
        button.go--back--button {
            margin: 0 auto;
            background-color: var(--owner-complement-shade-two);
            color: 8px 12px;
            padding: 8px 12px;
            color: #fff;
            outline: none;
            border: none;
            border-radius: 4px;
            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
        }
    }
}