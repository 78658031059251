div.LandingPage {
    outline-offset: -3px;
    p {
        font-size: 20px;
        text-align: center;
    }
    header {
        min-height: 70px;
        box-shadow: var(--shadow-type-one);
        display: flex;
        align-items: center;
        width: 100%;
        z-index: 10;
        div.content {
            margin: auto;
            flex-grow: 1;
            max-width: 1300px;
            padding: 0 20px;

            display: flex;
            justify-content: space-between;
            div.club--identification {
                display: flex;
                justify-content: center;
                font-size: 20px;
                flex-direction: column;
            }
            div.header--links {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                div.links {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    button.dashboard--button {
                        color: inherit;
                        background-color: transparent;
                        padding: 8px 12px;
                        font-size: 14px;
                        border: none;
                        border-radius: 4px;
                        text-transform: inherit;
                    }
                    a {
                        color: inherit;
                        font-size: 14px;
                        &:first-of-type {
                            margin-right: 10px;
                            padding-right: 10px;
                        }
                    }
                }
               div.club--information {
                   color: inherit;
               }

               div.standard--menu {
                   @media (max-width: 600px) {
                       display: none;
                   }
               }

               div.mobile--menu {
                    position: relative;
                    display: none;
                    button.LogOut{
                        padding-bottom: 0px;
                    }
                    
                    @media (max-width: 600px) {
                        display: block;
                    }
                    
               }
            }
        }

        img {
            max-height: 70px;
            width: auto;
            justify-self: center;
            @media (max-width: 600px) {
                display: none;
            }
        }
    }
    div.game--images {
        background-color: #383535;
        @media (max-width: 600px) {
            padding: 10px;
        }
        div.game--images__header {
            color: #fff;
            
            * {
                color: #fff;
            }
        }

        div.images--container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            row-gap: 15px;
            column-gap: 20px;
            max-width: 1300px;
            @media (max-width: 600px) {
                padding: 10px;
                grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            }
            img {
                width: 100%;
                height: auto;
            }
        }

        div.action--prompt {
            color: #fff;
            text-align: center;

        }
        button.action--prompt--link,
        a.action--prompt--link {
            display: inline-block;
            padding: 10px 15px;
            background-color: var(--owner-accent-one-shade-two);
            color: #fff;
            min-width: 180px;
            border-radius: 3px;
            font-size: 18px;
            text-align: center;
        }
    }
    section.details--section {
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        color: #fff;
        background: rgb(36,92,26);
        background: linear-gradient(90deg, rgba(36,92,26,1) 30%, rgba(82,142,30,1) 78%);
        div.details--section__content {
            div.detail--items {
                display: flex;
                justify-content: space-between;
                @media (max-width: 600px) {
                    flex-wrap: wrap;
                }
                div.item {
                    max-width: 400px;
                    font-size: 18px;
                    flex: 0 1 400px;
                    text-align: center;
                    @media (max-width: 600px) {
                        flex: 0 1 100%;
                        max-width: 100%;
                    }
                    img {
                        width: 60%;
                        height: auto;
                    }
                    div.detail--item--text {
                        margin-top: 20px;
                        span {
                            margin-right: 6px;
                        }
                        display: flex;
                        justify-content: center;
                        * {
                            max-width: 300px;
                            color: #fff;
                            display: inline;
                            text-align: left;
                        }
                    }
                    margin-bottom: 15px;
                }
            }
        }

        a.download--link {
            background-color: #22390d;
            border-radius: 4px;
            display: inline-block;
            color: #fff;
            box-shadow: var(--shadow-type-one);
            @media (max-width: 600px) {
                font-size: 18px;
            }
        }

        div.need--help {
            background-color: #172a05;
            min-height: 100px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            p {
                color: inherit;
                margin-bottom: 10px;
            }
        }
    }
}