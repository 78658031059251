div.Tree {
    background-color: #fff;
    border-radius: 4px;
    div.node--label.active {
        background-color: #F5F5F5;
    }

    div.tree--node {
        div.node--label {
            font-size: 13px;
            display: flex;
        }
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;
        svg {
            margin: 3px;
        }
    }

    span.node--text {
        color: var(--medium-grey);
        display: flex;
        align-items: center;
        margin-right: 5px;
        user-select: none;
    }
}