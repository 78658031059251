header.OwnerHeader {
    background-color: var(--owner-primary);
    align-items: center;
    color: #fff;
    position: fixed;
    width: 100%;
    height: 80px;
    display: grid;
    grid-template-columns: repeat(2, auto) 1fr;
    z-index: 10;

    // @media (max-width: 768px) {
    //     grid-template-columns: repeat(2, auto);
    //     button.IconButton {
    //         display: none;
    //     }
    // }

    div.header--text {
        font-size: 20px;
        span.label {
            font-size: 11px;
        }
        span {
            display: block;
        }
        @media (max-width: 600px) {
            font-size: 14px;
        }
    }

    div.button--container {
        a {
            color: #fff;
            display: flex;
            align-items: center;
            margin-right: 15px;
            svg {
                font-size: 26px;
                margin-right: 10px;
            }
        }
        display: flex;
        justify-content: flex-end;
        @media(max-width: 600px) {
            a, button {
                svg {
                    font-size: 18px;
                }
            }
        }
    }
}