.Owner {
    height: 100%;
    min-height: 100vh;
    display: flex;

    div.owner--content {
        overflow: auto;
        flex: 0 1 100%;
        background-color: var(--background-color);
        div.owner--club--header {
            color: var(--owner-complement-shade-one);
            font-weight: 400;
            font-size: 25px;

            span.club--id {
                font-size: 20px;
            }
        }
    }

    button.Button {
        background-color: var(--owner-accent-one-shade-three);
    }

    div.input--group--container {
        grid-template-columns: 85px 1fr;
        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
        span.input--group--icon--label {
            text-align: left;
            color: var(--owner-primary-shade-one);
        }
        div.InputGroup {
            label {
                &:focus-within {
                    border-color: var(--owner-accent-two-shade-three);
                }

                &:focus-within span.input--group__icon {
                    color: var(--owner-accent-two-shade-three);
                }
            }
        }
        span.input--group__icon {
            color: var(--owner-primary-shade-one);
        }
    }

    div.FileInput {
        div.image--placeholder {
            height: 300px;
        }
        label {
            background-color: var(--owner-primary-shade-one);
        }
    }

    div.Container {
        @media (max-width: 768px) {
            padding-bottom: 80px;
        }

        @media (max-width: 500px) {
            padding-bottom: 65px;
        }
    }
}