div.InGameAccountsList {
    button.add--in--game--account {
        margin-top: 30px;
        svg {
            color: var(--owner-complement-shade-two);
        }
    }

    div.account--actions {
        display: flex;
        align-items: center;
        a {
            color: var(--accent-one-shade-two);
            padding: 0;
            display: flex;
            align-items: center;
            svg {
                font-size: 16px;
            }
        }
    }
}