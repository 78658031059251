:root {
    --shadow-type-one: 0 11px 15px -7px rgba(0, 0 , 0, 0.2), 
        0 24px 38px 3px rgba(0 ,0 ,0 , 0.14),
        0 9px 46px 8px rgba(0, 0, 0, 0.12);

    --shadow-type-two: 0 5px 5px -3px rgba(0,0,0,.2),
        0 8px 10px 1px rgba(0,0,0,.14),
        0 3px 14px 2px rgba(0,0,0,.12);

    --shadow-type-three: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    --shadow-type-four: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);

    --background-color: #EEF0F8;
    --dark-text: #212529;
    --dark-red: #b71c1c;

    --old--accent-one-shade-one: #cb87af;
    --old--accent-one-shade-two: #a95486;
    --old--accent-one-shade-three: #651142;
    --old--accent-one-shade-four: #440028;

    --accent-one-shade-one: #ffc164;
    --accent-one-shade-two: #ffb039;
    --accent-one-shade-three: #c67600;

    --accent-two-shade-one: #ffd0aa;
    --accent-two-shade-two: #d4996a;
    --accent-two-shade-three: #804415;
    --accent-two-shade-four: #552600;

    --accent-three-shade-one: #8dcf8a; 
    --accent-three-shade-two: #5aac56;
    --accent-three-shade-three: #156711;
    --accent-three-shade-four: #034500;

    --accent-four-shade-one: #FFB300; 
    --accent-four-shade-two: #FFA000;
    --accent-four-shade-three: #FF8F00;
    --accent-four-shade-four: #FF6F00;

    --complement-shade-one: #fda8ab;
    --complement-shade-two: #d3696c;
    --complement-shade-three: #7e1518;
    --complement-shade-four: #540002;

    --cyan-300: #4DD0E1;
    --cyan-400: #26C6DA;
    --cyan-500: #00BCD4;
    --cyan-600: #00BCD4;

    --grey: #5f6281;
    --grey-highlight: #f4f6f9;
    --light-cyan: #f7fafc;

    --light-grey: #B5B5C3;
    --medium-grey: #3F4254;

    --light-background: #fff;
    --link-color: #1565C0;

    --status--requested--background: rgba(93,120,255,.1);
    --status--requested--color: #5d78ff;

    --status--success--background: rgba(10,187,135,.1);
    --status--success--color: #0abb87;

    --status--failure--background: rgba(253,57,122,.1);
    --status--failure--color: #fd397a;

    --status--pending--background: rgba(255,184,34,.1);
    --status--pending--color: #ffb822;
}