label.SearchInput {
    font-size: 14px;
    position: relative;
    display: inline-block;
    &:focus-within {
        span.input--placeholder {
            transform: translateY(-24px) scale(0.8);
        }
        div.input--border {
            transform: scaleX(1);
        }
    }
   
    span.input--placeholder {
        position: absolute;
        bottom: 5px;
        color: rgba(0, 0, 0, 0.5);
        transition: transform 230ms ease-in-out, color 230ms ease-in-out;
        transform-origin: left;
        font-size: 15px;
        left: 0;
    }
    input {
        outline: none;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        padding: 6px 0;
        &[data-has-content=true] ~ span.input--placeholder {
            transform: translateY(-24px) scale(0.8);
        }
    }
    div.input--border {
        background: rgba(0, 0, 0, 0.8);
        content: '';
        width: 100%;
        height: 2px;
        transform: scaleX(0);
        border-radius: 2px;
        transition: transform 300ms ease-in-out;
        position: relative;
        bottom: 1px;
    }
}