.margin-0 {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.margin-6 {
    margin: 6px;
}

.margin-10 {
    margin: 10px;
}

.margin-20 {
    margin: 20px;
}

.margin-30 {
    margin: 30px;
}

.margin-40 {
    margin: 40px;
}

.margin-left-right-auto {
    margin-left: auto;
    margin-right: auto;
}

.margin-left-right-20 {
    margin-left: 20px;
    margin-right: 20px;
}


.margin-left-right-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.margin-left-right-40 {
    margin-left: 40px;
    margin-right: 40px;
}


.margin-left-right-60 {
    margin-left: 60px;
    margin-right: 60px;
}

.margin-top-bottom-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-top-bottom-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-top-bottom-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-top-bottom-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.margin-top-bottom-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-right-0 {
    margin-right: 0;
}

.margin-right-3 {
    margin-right: 3px;
}

.margin-right-4 {
    margin-right: 4px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-6 {
    margin-right: 6px;
}

.margin-right-7 {
    margin-right: 7px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-30 {
    margin-right: 30px;
}

.margin-right-40 {
    margin-right: 40px;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-25 {
    margin-left: 25px;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-70 {
    margin-top: 70px;
}

.margin-top-80 {
    margin-top: 80px;
}