div.VerifyMobileNumber {
    img.mobile--phone {
        width: 120px;
        display: block;
    }

    button.edit--number--button {
        display: inline-flex;
        color: var(--accent-one-shade-two);
        padding: 0;
        position: relative;
        align-items: center;
        bottom: -6px;
        background-color: transparent;
        outline: none;
        border: none;
        svg {
            font-size: 20px;
        }
    }
}