div.Select {
    display: inline-block;
    position: relative;
    width: 100%;
   
    span.select--text {
        position: absolute;
        color: rgba(0, 0, 0, 0.6);
        font-size: 15px;
        transition: transform 230ms ease-in-out, color 230ms ease-in-out;
        top: -2px;
        transform-origin: top left;
    }

    input {
        outline: none;;
        border: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.8);
        border-radius: 1px;
        background-color: transparent;
        cursor: pointer;
        color: var(--grey);
        font-size: 15px;
        padding-bottom: 5px;
        min-width: 1px;
        text-overflow: ellipsis;
        width: 100%;
        transition: border-bottom-color 250ms ease-in-out;
    }

    &:focus-within input {
        border-bottom-color: var(--accent-one-shade-one);
    }

    svg.select--icon {
        position: absolute;
        right: -3px;
        cursor: pointer;
        transition: transform 200ms ease-in-out;
    }

    div.list--section {
        z-index: 1;
        position: absolute;
        background-color: #fff;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 
            0px 8px 10px 1px rgba(0, 0, 0, 0.14), 
            0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        top: 30px;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: transform 180ms ease-in-out, opacity 240ms ease-in-out;
        transform-origin: top left;
        max-height: 240px;
        overflow: auto;

        div.search--bar {
            position: relative;
            padding: 10px;
            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
                font-size: 20px;
            }
            input {
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 4px;
                cursor: initial;
                padding: 5px 30px;
            }
        }
    }
    ul.select--list {
        li {
            cursor: pointer;
            padding: 10px 15px;
            transition: background-color 200ms ease-in-out;
            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }

    div.select--error {
        color: var(--dark-red);
        font-size: 12px;
        word-wrap: break-all;
        min-height: 18px;
    }
}