div.LoadingScreen {
    position: fixed;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.98);
    
    z-index: 1000009;
    visibility: hidden;
    opacity: 0;
    transition: z-index 1800ms ease-in-out, visibility 280ms ease-in-out, opacity 280ms ease-in-out;
    div.loading--element {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    div.loading--element div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #5387bd;
        animation: LoadingScreen 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    div.loading--element div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }
    div.loading--element div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }
    div.loading--element div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }
    @keyframes LoadingScreen {
        0% {
        top: 8px;
        height: 64px;
        }
        50%, 100% {
        top: 24px;
        height: 32px;
        }
    }
}