:root {
    --owner-primary: #1e1e2d;

    --owner-primary-shade-one: #29293D;
    --owner-primary-shade-two: #181825;
    --owner-primary-shade-three: #101018;
    --owner-primary-shade-four: #08080C;

    --owner-base: #ff9900;
    --owner-accent-one-shade-one: #ffc164;
    --owner-accent-one-shade-two: #ffb039;
    --owner-accent-one-shade-three: #c67600;

    --owner-accent-two-shade-one: #ffdb64;
    --owner-accent-two-shade-two: #ffd239;
    --owner-accent-two-shade-three: #c69800;

    --owner-accent-three-shade-one: #ff9864;
    --owner-accent-three-shade-two: #ff7c39;
    --owner-accent-three-shade-three: #c64300;

    --owner-complement-shade-one: #5387bd;
    --owner-complement-shade-two: #316dac;
    --owner-complement-shade-three: #094481;
}