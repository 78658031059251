div.ForgotPassword {
    height: 100vh;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #d1913c; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #d1913c, #ffd194); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #d1913c, #ffd194); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
    div.links {
        text-align: center;
        a {
            color: var(--accent-one-shade-three) !important;
            font-size: 15px;
        }
        span {
            margin: 0 10px;
        }
    }

    div.password--confirmation {
        padding: 25px 20px;
        background-color: var(--accent-two-shade-one);
        border-radius: 2px;
        color: var(--accent-two-shade-three);
        border: 1px solid var(--accent-two-shade-two);
    }
    
    div.forgot--password--window {
        border-radius: 4px;
        background-color: #fff;
        padding: 40px 60px;
        max-width: 500px;
        box-shadow: var(--shadow-type-one);
    }
}