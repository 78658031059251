div.MemberReferral {
    margin: 0 auto;
    color: #fff;
    position: relative;

    div.image--container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 20px;
        h2.page--header {
            font-weight: 500;
            text-align: center;
            font-size: 40px;
            margin-top: 60px;
            @media (max-width: 600px) {
                font-size: 24px;
                margin-top: 80px;
            }
        }

        div.referral--code--notice {
            user-select: none;
            margin-top: 40;
            @media (max-width: 600px) {
                margin-top: 10px;
            }
        }

        div.referral--code {
            font-size: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        button.clipboard--button {
            background-color: transparent;
            color: var(--accent-three-shade-three);
            border: none;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            border-radius: 50%;
        }

        div.form--notice {
            user-select: none;
            text-align: center;
            margin-top: 30px;
            font-size: 20px;
            @media (max-width: 600px) {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }

    section.main--section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 18px;

        form {
            max-width: 600px;
        }
    }
    
}