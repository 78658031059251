div.LogIn {
    min-height: 100vh;
    background-size: contain;
    background-color: #fff;
    display: flex;
    justify-content: center;

    div.login--window {
        max-width: 430px;
        width: 100%;
        border-radius: 2px;
        flex: 0 1 395px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        h1.login--window__header {
            font-weight: 500;
            color: var(--accent-one-shade-two);
        }

        span.error--message {
            color: var(--dark-red);
            font-size: 13px;
        }
    }

    div.block {
        align-items: center;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 13px;
        span {
            font-size: 13px;
            &.no--account--text {
                @media (max-width: 330px) {
                    font-size: 11px;
                }
            }
        }
    }

    a.forgot--password--link {
        color: var(--accent-four-shade-three);
        justify-self: flex-end;
    }
}