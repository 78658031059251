div.PurchaseChips {
    
    div.payment--input {
        background-color: var(--light-cyan);

        @media (max-width: 600px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    div.select--container {
        max-width: 350px;
        @media (max-width: 600px) {
            max-width: 100%;
        }
        &:not(:first-of-type) {
            margin-top: 60px;
        }
    }

    div.section--border {
        height: 1.5px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 2px;
    }

    div.payment--information {
        div.payment--information--details {
            border: 2px solid #F5F5F5;
            background-color: var(--light-cyan);
            @media (max-width: 600px) {
                padding-left: 10px;
                padding-right: 10px;
            }

            div.payment--information--item:not(:first-of-type) {
                margin-top: 10px;
            }
        }
        span.value {
            font-weight: 300;
        }
        span.caption {
            margin-right: 15px;
            font-weight: 500;
        }
    }
}