div.ReferralSettings {
    div.referral--code--update {
        max-width: 450px;
    }
}

.toggle-switch{
  padding: 20px !important;
  padding-left: 35px !important;

  .MuiSwitch-colorSecondary.Mui-checked {
    color: #52d869 !important;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #52d869;
  }
}