div.MembershipPrompt {
    div.confirmation--buttons {
        button {
            background-color: transparent;
            border: none;
            font-size: 15px;
            padding: 8px 12px;
            border-radius: 4px;
            width: 75px;
            cursor: pointer;
            &.cancel--button {
                color: var(--dark-red);
                &::after {
                    background-image: radial-gradient(circle, #ffcdd2 10%, transparent 10%);
                }
            }

            &.confirm--button {
                color: var(--accent-four-shade-one);
                &::after {
                    background-image: radial-gradient(circle, #FFF3E0 10%, transparent 10%);
                }
            }
        }
    }
}