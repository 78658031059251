div.Card {
    background-color: #fff;
    border-radius: 4px;
    max-width: 350px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    div.image--container {
        height: 140px;
        object-fit: contain;
        img {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            height: inherit;
            object-fit: cover;
            width: 100%;
            user-select: none;
        }
    }

    div.text--content {
        padding: 20px;
        h2.title {
            font-size: 18px;
            font-weight: 500;
            color: var(--dark-text);
        }
        p.description {
            margin: 14px 0;
            color: rgba(0, 0, 0, 0.55);
            font-size: 13px;
        }
    }

    div.card--action {
        padding: 0 10px 20px 10px;
    }
}