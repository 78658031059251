div.input--group--container {
    display: flex;
    flex-direction: column;
    span.input--group--icon--label {
        margin-right: 10px;
        margin-bottom: 3px;
        text-align: left;
        color: var(--dark-text);
        font-size: 13px;
        font-weight: 300;
        @media (max-width: 600px) {
            margin-bottom: 8px;
            text-align: left;
        }
    }
}

.font-weight-600 {
    font-weight: 600;
}

.ripple {
    position: relative;
    overflow: hidden;

    &:not([disabled]):active::after {
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
        transition: 0s;  
    }

    &::after {
        content: '';
        position: absolute;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(15);
        background-image: radial-gradient(circle, #fff 10%, transparent 10%);
        transition: transform 600ms ease-in-out, opacity 600ms ease-in-out;
        opacity: 0;
    }
    &.dark::after {
        background-image: radial-gradient(circle, rgba(0, 0, 0, 0.2) 10%, transparent 10%);
    }
}

.max-width-200 {
    max-width: 200px;
}

.max-width-300 {
    max-width: 300px;
}

.max-width-400 {
    max-width: 400px;
}

.max-width-500 {
    max-width: 500px;
}

.max-width-600 {
    max-width: 600px;
}

.max-width-700 {
    max-width: 700px;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}