.padding-10 {
    padding: 10px;
}

.padding-15 {
    padding: 15px;
}

.padding-20 {
    padding: 20px;
}

.padding-30 {
    padding: 30px;
}

.padding-40 {
    padding: 40px;
}

.padding-left-right-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.padding-left-right-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.padding-left-right-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.padding-left-right-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.padding-left-right-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.padding-right-10 {
    padding-right: 10px;
}

.padding-right-20 {
    padding-right: 20px;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-top-bottom-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.padding-top-bottom-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-top-bottom-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-top-bottom-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-top-bottom-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.padding-top-bottom-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-top-bottom-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-top-bottom-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}