div.Modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: -5;
    transition: background-color 300ms ease-in-out;
    background-color: transparent;
    color: var(--dark-text);
    div.modal--window {
        box-shadow: var(--shadow-type-one);
        width: 90%;
        max-width: 400px;
        background-color: #fff;
        position: absolute;
        top: 43%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 2px;
        transition: top 200ms ease-in-out, opacity 320ms ease-in-out;
        opacity: 0;

        div.modal--header {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding: 20px 25px;
            position: relative;
            height: 79px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
                color: black;
                right: 0;
                padding: 10px;
                position: relative;
                right: -15px;
            }
        }

        div.modal--content {
            font-weight: 300;
            min-height: 120px;
            font-size: 13px;
            padding: 20px 25px;
        }
    }

    div.confirmation--buttons {
        display: flex;
        justify-content: flex-end;
        button {
            margin: 0;
        }
        button.cancel {
            margin-right: 10px;
        }

    }
    div.modal--content {
        width: 100%;
        flex: 100%;
        font-weight: 300;
        min-height: 120px;
        display: flex;
        align-items: center;
        font-size: 13px;
        div.modal--data--content {
            width: inherit;
        }
        div.row {
            display: flex;
            color: var(--dark-text);
            font-size: 13px;
            div.key {
                color: var(--medium-grey);
            }
            &:not(:first-of-type) {
                margin-top: 10px;
            }
            div:first-of-type {
                min-width: 170px;
                margin-right: 6px;
            }
        }
    }
}