div.Container {
    width: 100%;
    padding: 20px;
    overflow: auto;
    background-color: var(--background-color);
    height: inherit;
    @media (max-width: 600px) {
        padding: 10px;
    }
    div.container--content {
        border-radius: 4px;
        padding: 30px;
        margin: 0 auto;
        
        @media(max-width: 600px) {
            padding: 10px;
        }
    }

    div.container--content__constrain {
        margin: 0 auto;
    }
}