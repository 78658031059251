div.Menu {
    position: relative;
    z-index: 100;
    button.menu--button {
        height: 100%;
        width: 50px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        float: right;
        background-color: transparent;
        border: none;
        outline: none;
        border-radius: 50%;
        span.bar {
            position: relative;
            display: inline-block;
            width: 30px;
            height: 2px;
            background-color: var(--accent-one-shade-one);
            margin: 4px 0;
            border-radius: 5px;
            transition: top 220ms ease-in-out, 
                        bottom 220ms ease-in-out, 
                        transform 220ms ease-in-out, 
                        opacity 220ms ease-in-out;
            &:first-of-type {
                top: 0;
            }
            &:nth-of-type(3) {
                bottom: 0;
            }
        }
    }

    ul.menu--list {
        box-shadow: 0 15px 50px 0 rgba(82, 63, 105, .15);;
        background-color: #fff;
        width: 220px;
        color: #757575;
        border-radius: 5px;
        margin-left: auto;
        position: absolute;
        z-index: 100;
        right: 10px;
        top: 50px;
        visibility: hidden;
        transform: scale(0.95);
        transition: transform 170ms ease-in-out, opacity 230ms ease-in-out;
        transform-origin: top;
        li {
            a {
                &:hover div.link--content {
                    background-color: rgba(0, 0, 0, 0.04);
                }
                div.link--content {
                    transition: background-color 260ms ease-in-out;
                }
            }
    
            &:first-of-type a {
                padding-top: 15px;
            }

            &:last-of-type a {
                padding-bottom: 15px;
            }
    
            a {
                display: block;
                font-size: 14px;
                div.link--content {
                    display: flex;
                    align-items: center;
                    color: #757575;
                    padding: 10px;
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
    
            button {
                padding-bottom: 20px;
                width: 100%;
                &:hover div.button--content {
                    background-color: rgba(0, 0, 0, 0.04);
                }
                div.button--content {
                    padding: 10px;
                    padding-left: 30px;  
                    padding-right: 30px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    transition: background-color 260ms ease-in-out;
                }
            }
            
            svg {
                font-size: 20px;
                margin-right: 10px;
            }
        }
    }
}