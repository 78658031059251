div.FormHeader {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
    div.header {
        @media (max-width: 400px) {
            flex: 0 1 100%
        }
        div.label {
            font-weight: 600;
            font-size: 17px;
        }
        div.description {
            color: var(--light-grey);
            font-size: 13px;
        }
    }

    button.save--button {
        @media (max-width: 400px) {
            flex: 0 1 100%;
            margin-top: 15px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        height: 39px;
        width: 123px;
        position: relative;
        padding: 10px 15px;
        border-radius: 4px;
        background-color: var(--cyan-400);
        color: #fff;
        outline: none;
        border: none;
        font-size: 14px;
        font-weight: 300;
        transition: background-color 220ms ease-in-out;
        &:hover {
            background-color: var(--cyan-600);
        }

        div.MuiCircularProgress-root {
            svg {
                color: #757575;
            }
        }

        &[data-loading="true"] {
            background-color: #9E9E9E;
            cursor: initial;
        }
    }
}