div.OwnerAccountsList {
    a.navigation--link {
        display: flex;
        align-items: center;
        font-size: 13px;
    }

    button.add--account--button {
        color: var(--owner-complement-shade-two);
    }

    div.owner--account--actions {
        display: flex;
        
        button, a {
            text-transform: uppercase;
        }

        svg {
            font-size: 15px;
        }

        button {
            width: 80px;
            height: 35px;
            margin: 0 0;
            background-color: transparent;
            color: var(--dark-red);
            box-shadow: none;
        }
        a.edit--account--link {
            color: var(--owner-accent-one-shade-three);
        }
    }
}