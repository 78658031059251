div.OwnerMembers {
    label.SearchInput {
        &:focus-within {
            span.input--placeholder {
                transform: translateY(-24px) scale(0.8);
                color: var(--primary-shade-three);
            }
        }
        div.input--border {
            background-color: var(--owner-complement-shade-three); 
        }
    }

    div.search--input--container {
        padding: 20px 20px 0 20px;
        background-color: #fff;
    }
}