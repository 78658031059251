div.OwnerClubActivity {
    div.club--activity--header {
        font-size: 30px;
        font-weight: 500;
        color: var(--owner-complement-shade-three);
    }

    section.editor--section {
        max-width: 900px;
        @media (max-width: 600px) {
            margin-top: 20px;
        }
        div.ql-editor {
            color: var(--dark-text);
            min-height: 450px;
            max-height: 450px;
        }
    }
}