div.MemberRegistration {
    overflow: auto;
    padding: 20px;
    background-color: var(--background-color);
    @media (max-width: 768px) {
        padding: 20px 10px;
    }
    
    div.member--registration--content {
        margin: 0 auto;
        background-color: #fff;
        max-width: 800px;
        padding: 20px 40px;

        @media (max-width: 768px) {
            padding: 20px 10px;
        }
    }

    div.back--button--container {
        position: relative;
    }
    div.back--button {
        position: absolute;
    }
}