div.AsideTab {
    background-color: var(--background-color);
    @media (max-width: 650px) {
        padding: 10px;
    }

    div.aside--tab--content--container {
        background-color: #fff;
        display: flex;
        @media (max-width: 650px) {
            flex-direction: column;
        }
        ul.aside--tab__navigation {
            min-width: 300px;
            border-right: 2px solid rgba(0, 0, 0, 0.03);
            @media (max-width: 650px) {
                padding: 10px;
            }
            a.aside--tab__navigator {
                outline: none;
                color: var(--medium-grey);
                display: flex;
                align-items: center;
                padding: 10px 20px;
                &.active {
                    background-color: #f4f6f9;
                    svg {
                        color: var(--accent-one-shade-two);
                    }
                }
    
                svg {
                    font-size: 40px;
                    margin-right: 10px;
                }
    
                div {
                    text-align: left;
                }
    
                div.link--name {
                    font-size: 13px;
                }
    
                div.link--description {
                    font-weight: 300;
                    font-size: 12px;
                    color: var(--light-grey);
                }
            }
        }

        div.aside--tab__content {
            flex: 1 1 auto;
            overflow-x: auto;
            @media (max-width: 650px) {
                padding: 10px;
            }
        }
    }
}