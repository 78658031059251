div.MemberProfile {
    padding: 20px;
    text-align: center;
    @media (max-width: 768px) {
        padding: 10px;
    }
    div.member--profile--content {
        padding: inherit;
        min-height: inherit;
        background-color: #fff;
        div.tab--element--container {
            max-width: 1000px;
            margin: 0 auto;
        }
    }
}