div.OwnerSettings {
    height: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
    }

    div.overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: transparent;
        transition: background-color 240ms ease-in-out;
    }

    div.mobile--header {
        background-color: #fff;
        padding: 10px 20px;
        box-shadow: var(--shadow-type-four);
        display: none;
        @media (max-width: 768px) {
            display: block;
        }
        
        button {
            display: flex;
            align-items: center;
            outline: none;
            background-color: transparent;
            border: none;
            &:hover {
                svg {
                    color: #3699FF;
                }
            }
            svg {
                transition: color 220ms ease-in-out;
                color: var(--light-grey);
                margin-right: 12px;
                font-size: 35px;
                font-weight: 600;
            }
            span {
                font-size: 15px;
                line-height: 15px;
            }       
        }
    }
    div.content {
        display: flex;
        overflow: auto;
        justify-content: space-between;
        max-width: 1300px;
        margin: 0 auto;
        padding: 20px;
        height: 100%;
        max-height: 100%;
        @media(max-width: 768px) {
            height: auto;
        }
    }

    section.navigation--section {
        flex: 0 1 30%;
        min-width: 350px;
        background-color: #fff;
        padding: 20px 30px;
        border-radius: 5px;
        box-shadow: var(--shadow-type-four);
        transition: left 240ms ease-in-out;
        @media(max-width: 768px) {
            position: fixed;
            z-index: 10;
            height: 100vh;
            top: 0;
            left: -300px;
            border-radius: 0px;
            min-width: 300px;
        }
        div.owner--name {
            font-weight: 600;
        }

        div.owner--label {
            color: var(--light-grey);
            font-size: 13px;
            margin-top: 3px;
        }

        div.contact--information {
            margin-top: 30px;
            div.key--value--pair {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                div.key {
                    color: var(--medium-grey);
                }
                div.value {
                    color: var(--light-grey);
                }
                &:not(:first-of-type) {
                    margin-top: 10px;
                }
            }
        }

        ul.navigator {
            margin-top: 30px;
            li {
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 220ms ease-in-out;
                &:hover {
                    background-color: #F3F6F9;
                    svg {
                        color: #3699FF;
                    }
                }
                &:not(:first-of-type) {
                    margin-top: 6px;
                }
                a {
                    padding: 15px;
                    outline: none;
                    color: var(--medium-grey);
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    svg {
                        margin-right: 10px;
                        font-size: 18px;
                        transition: color 220ms ease-in-out;
                    }
                    &.active {
                        background-color: #F3F6F9;
                        svg {
                            color: #3699FF;
                        }
                    }
                }
            }
        }
    }

    section.navigation--content {
        flex: 0 1 69%;
        margin-left: 10px;
        max-width: 100%;
        @media(max-width: 768px) {
            margin-left: 0px;
            flex: 0 1 100%;
        }

        form.navigation--content--form {
            width: 100%;
            border-radius: 5px;
            box-shadow: var(--shadow-type-four);
            background-color: #fff;

            div.form--input {
                padding: 20px 30px;
            }
        }
    }
}