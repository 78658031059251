.Heading {
    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }

    &.level-one {
        font-size: 30px;
    }

    &.level-two {
        font-size: 24px;
    }

    font-weight: 400;
    color: var(--medium-grey);
}