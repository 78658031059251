.Member {
    div.back--button {
        display: none;
        @media (max-width: 900px) {
            display: block;
        }
        a {
            outline: none;
            display: flex;
            align-items: center;

            img {
                margin-right: 10px;
            }
            span {
                color: var(--complement-shade-three);
            }
        }
    }
}