div.Snackbar {
    z-index: 15;
    position: fixed;
    background-color: rgb(50, 50, 50);
    color: #fff;
    left: 50%;
    bottom: 40px;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);

    transition: transform 280ms ease-in-out, opacity 280ms ease-in-out, z-index 280ms ease-in-out;
    opacity: 0;
    transform: scale(0.8);

    @media(max-width: 600px) {
        min-width: 95vw;
    }
}