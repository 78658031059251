div.FileInput {
    div.image--placeholder {
        max-width: 300px;
        transition: height 300ms ease-in-out;
        z-index: 2;
        img {
            width: 100%;
            transition: opacity 250ms ease-in-out;
            transform-origin: center;
        }
    }

    label {
        border-radius: 2px;
        display: inline-flex;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
        cursor: pointer;
        input[type=file] {
            position: absolute;
            visibility: hidden;
        }

        background-color: var(--accent-two-shade-two);
        color: #fff;
        font-weight: 300;
        z-index: 1;
        @media (max-width: 400px) {
            width: 100%;
            justify-content: space-between;
        }
    }

    div.file--input--error {
        color: var(--dark-red);
        font-size: 13px;
        min-height: 18px;
    }
}